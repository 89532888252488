
import './App.css';

import Adminroute from './Routes/Adminroute';


function App() {
  return (
    <div >
  
   <Adminroute/>
   
    </div>
  );
}

export default App;

import React from 'react';
import { FaFacebookF, FaLinkedinIn, FaInstagram, FaYoutube, FaTwitter, FaHome, FaMobile, FaRegCopyright } from 'react-icons/fa';
import { IoIosMail } from 'react-icons/io';
import { FaFacebook } from "react-icons/fa6";
export const Footer = () => {
  return (
    <footer className=' ' style={{backgroundColor:'rgba(0,0,0,0.1)'}}>
      <div className="container my-5 ">
       
        <div className="row g-3 justify-content-center">
          {/* Company Logo and Description */}
          <div className="col-12 col-sm-12 col-lg-3 ">
            <div className="footer-logo">
              <a href="#">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrI30YYaYo2x_jjhbhvYY9I6zUO5ek4XOGYQ&s" alt="Inrecruit HR Services Logo" className="img-fluid navbar-brand mx-auto d-block" style={{ maxWidth: '180px' }} />
              </a>
            </div>
            <div className="footer-content">
              <p style={{ textAlign: 'justify' }}>
                Elevate your career with premier overseas job placements at Inrecruit HR Services. Get expert guidance for international job opportunities. Contact us now for tailored career solutions!
              </p>
            </div>
            <div className="container">
              <div className="d-flex align-items-center justify-content-evenly">
            <div className="mb-3 mt-2"><a href=""><span className=" text-white rounded-circle ps-2 pb-2 pt-1 pe-2 fs-6" style={{backgroundColor:'#fe5722',color:'#fff'}}><FaFacebook /></span></a></div> 
             <div className="mb-3 mt-2"><a href=""><span className=" text-white rounded-circle ps-2 pb-2 pt-1 pe-2 fs-6" style={{backgroundColor:'#fe5722',color:'#fff'}}><FaLinkedinIn /></span></a></div>
             <div className="mb-3 mt-2"><a href=""><span className=" text-white rounded-circle ps-2 pb-2 pt-1 pe-2 fs-6" style={{backgroundColor:'#fe5722',color:'#fff'}}><FaInstagram /></span></a></div>
             <div className="mb-3 mt-2"><a href=""><span className=" text-white rounded-circle ps-2 pb-2 pt-1 pe-2 fs-6" style={{backgroundColor:'#fe5722',color:'#fff'}}><FaYoutube /></span></a></div>
             <div className="mb-3 mt-2"><a href=""><span className=" text-white rounded-circle ps-2 pb-2 pt-1 pe-2 fs-6" style={{backgroundColor:'#fe5722',color:'#fff'}}><FaTwitter /></span></a></div>
            </div>
              </div>
          </div>

          {/* Useful Links */}
          <div className="col-12 col-sm-12 col-lg-6 mt-4">
            <div className="link-header">
              <h4 className="text-capitalize fw-bold text-center  ">Useful Links</h4>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-evenly mt-4">
              {[
                ['Home', 'About Us', 'Services', 'Industries', 'Process'],
                ['Overseas Recruitment', 'Ticket Immigration', 'Permanent Hiring', 'Contract Hiring', 'Job Fair'],
              ].map((links, colIndex) => (
                <div key={colIndex} className="d-flex flex-column align-items-center">
                  <div className="footer-content">
                    {links.map((link, linkIndex) => (
                      <p key={linkIndex} className="text-capitalize fw-normal">{link}</p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Contact Information */}
          <div className="col-12 col-sm-12 col-lg-3 mt-4">
            <div className="link-header">
              <h4 className="text-capitalize text-center">Contact</h4>
            </div>
            <div className="contact-details">
              {[
                { icon: FaHome, text: 'Daaru Complex, No.17/A2, Gandhi Road, Alwarthirunagar, Chennai-600087, Tamilnadu, India' },
                { icon: IoIosMail, text: 'cv@inrecruit.in' },
                { icon: FaMobile, text: '+91-98403 11684' },
              ].map(({ icon: Icon, text }, index) => (
                <div key={index} >
               
                  <p className='fs-6 text-break' style={{ fontSize: '0.9rem', }}>  <span><Icon style={{ color: '#fe5722', fontSize: '1rem' }} /></span> {text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className="container-fluid" style={{ backgroundColor: '#0f2239', color: 'white' }}>
        <div className="container">
          <div className="row g-3">
            <div className="col-12">
              <p className='text-center' style={{ fontSize: '0.9rem' }}>
                <FaRegCopyright /> 2024 Copyright: InRecruit
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
